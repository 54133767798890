/* eslint-disable camelcase */
import React, {useState, useEffect} from 'react'
import {useStaticQuery, graphql, Link} from 'gatsby'
import {css} from '@emotion/core'
import Layout from '../components/Layout'
import {getTheme, getThemeStyles} from '../util/theme'

const IndexPage = () => {
    const site = useStaticQuery(
        graphql`
            query Routes {
                allSitePage {
                    edges {
                        node {
                            path
                        }
                    }
                }
            }
        `,
    )

    const allRoutes = site.allSitePage.edges.map((edge) => edge.node.path)
    const unLocalized = allRoutes.filter((route) => route.split('/').length <= 3).sort()
    const localized = allRoutes.filter((route) => route.split('/').length > 3).sort()

    const routesList = (routesList) => {
        return routesList.map((route, i) => {
            return (
                <div key={i}>
                    <Link
                        to={route}
                        css={css`
                            color: blue;
                        `}
                    >
                        {route}
                    </Link>
                </div>
            )
        })
    }

    const [theme, setTheme] = useState('light')
    const [themeStyles, setThemeStyles] = useState({})

    useEffect(() => {
        const theme = getTheme()
        const themeStyles = getThemeStyles()
        setTheme(theme)
        setThemeStyles(themeStyles)
    }, [themeStyles])

    return (
        <Layout
            title="startpage-static-pages"
            description="index page for startpage-static-pages"
            canonicalPath="/"
            themeStyles={themeStyles}
            noHeaderFooter
            theme={theme}
        >
            <div
                css={css`
                    background: #fff;
                    padding: 1rem 1rem 4rem;
                `}
            >
                <h1
                    css={css`
                        margin: 1rem 0 2rem;
                    `}
                >
                    Startpage Static Pages
                </h1>
                <div
                    css={css`
                        padding-left: 2rem;
                        h2 {
                            margin: 1.5rem 0;
                        }
                    `}
                >
                    <h2>pages</h2>
                    <div>{routesList(unLocalized)}</div>

                    <h2>localized pages</h2>
                    <div>{routesList(localized)}</div>
                </div>
            </div>
        </Layout>
    )
}

export default IndexPage
